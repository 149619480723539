<template>
    <div class="prospect">
      <v-row>
        <v-col cols="12" md="6" order="2" order-md="1">
            <v-card>
                <v-card-title>
                  <h2>Prospects</h2>
                </v-card-title>
                <v-data-table
                :headers="prospectHeaders"
                :items="prospects"
                class="elevation-1"
                :loading="checkProspects"
                loading-text="Searching Prospects..."
                :items-per-page="5"
                :no-data-text="noProspects"
                @click:row="setProspect"
                item-key="Grower_ID"
            >
            <template v-slot:item.add="props">
                <v-btn
                icon
                @click.stop="addProspect(props.item)"
                >
                <v-icon color="primary">mdi-plus-circle-outline</v-icon>
                </v-btn>
              </template>
            </v-data-table>
            </v-card>
        </v-col>
        <v-col cols="12" md="6" order="1" order-md="2">
            <Map @prospectCoords="prospectCoords" :growerId="growerId" :growerIds="growerIds" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card>
              <v-card-title>
                <h2>Customers</h2>
              </v-card-title>
              <v-data-table
              :headers="customerHeaders"
              :items="customers"
              class="elevation-1"
              :loading="checkCustomers"
              loading-text="Searching Customers..."
              :items-per-page="50"
              :no-data-text="noCustomers"
              hide-default-footer
              >
              <template v-slot:item.view="props">
                <v-btn
                v-if="canView(props.item.pfs_dealerid)"
                :to="`/customers/${props.item.accountid}`"
                target="_blank"
                icon
                >
                <v-icon>mdi-magnify</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </div>
  <!-- <div class="prospect">
    <v-container fluid>
      <v-row>
        <v-col>
          <v-form>
            <v-container>
              <v-row>
                <v-col cols="4">
                  <v-text-field
                    v-model="customer.name"
                    label="Farm Name"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-if="customer.primarycontactid"
                    v-model="customer.primarycontactid.firstname"
                    label="First Name"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-if="customer.primarycontactid"
                    v-model="customer.primarycontactid.lastname"
                    label="Last Name"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    v-model="customer.address1_line1"
                    label="Address"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    v-model="customer.address1_city"
                    label="City"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    v-model="customer.address1_stateorprovince"
                    label="State"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    v-model="customer.address1_postalcode"
                    label="Zip"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="customer.emailaddress1"
                    label="Email"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-col>
        <v-col>
          <Map @prospectCoords="prospectCoords" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <h2>Plans</h2>
          <v-row class="mt-1">
            <v-col class="flex-shrink-1 flex-grow-0" v-for="plan in plans" :key="plan.id">
              <v-btn :to="`/plans/${plan.id}`" large color="primary">{{ `${plan.year}` }}</v-btn>
            </v-col>
            <v-col class="flex-shrink-1 flex-grow-0">
              <v-btn :to="`/customers/${customer.accountid}/newPlan`" large color="primary">Add New Plan</v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div> -->
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Map from "@/components/Map"
export default {
  name: 'Prospect',
  async created() {
    if (this.customer) {
      this.$store.commit("customer/emptyCustomer")
    }
  },
  components: {
    Map
  },
  data() {
      return {
          prospects: [],
          index: null,
          radius: 1000,
          checkProspects: false,
          checkCustomers: false,
          initialSearch: false,
          initialCustomerSearch: false,
          growerId: null,
          growerIds: [],
          customers: [],
          customerHeaders: [
            {
                text: "Name",
                value: "name"
            },
            {
              text: "Email",
              value: "emailaddress1"
            },
            {
              text: "Phone",
              value: "telephone1"
            },
            {
                text: "Address",
                value: "address1_line1"
            },
            {
                text: "City",
                value: "address1_city"
            },
            {
                text: "State",
                value: "address1_stateorprovince"
            },
            {
                text: "Zip",
                value: "address1_postalcode"
            },
            {
                text: "",
                value: "view"
            }
          ],
          prospectHeaders: [
            {
                text: "Name",
                value: "Name"
            },
            {
                text: "Address",
                value: "Address_1"
            },
            {
                text: "City",
                value: "City"
            },
            {
                text: "State",
                value: "State_Code"
            },
            {
                text: "Zip",
                value: "Zip_Code"
            },
            {
                text: "",
                value: "add"
            }
          ]
        }
  },
  watch: {
    async token(val) {
      
    }
  },
  computed: {
    ...mapState("customer", ["customer"]),
    ...mapState("user", ["token", "user"]),
    ...mapGetters("user", ["isAdmin", "isRep"]),
    noProspects() {
        if (this.checkProspects) {
            return "Searching prospects..."
        }
        else if (!this.initialSearch) {
            return "Search for new prospect"
        }
        else {
            return "No prospects found"
        }
    },
    noCustomers() {
        if (this.checkCustomers) {
            return "Searching customers..."
        }
        else if (!this.initialCustomerSearch) {
            return "Please select prospect to discover current customers"
        }
        else {
            return "No customers found"
        }
    }
  },
  methods: {
    async addProspect(prosp) {
      let item = JSON.parse(JSON.stringify(prosp))
      item.First_Name = this.capitalize(item.First_Name)
      item.Last_Name = this.capitalize(item.Last_Name)
      item.City = this.capitalize(item.City)
      item.prospect = true
      item.userId = this.user.username
      if (this.isRep) {
        item.dealerId = this.user.dealerId
        item.dealerName = this.user.dealerInfo.pfs_name
      }
      if (item.Phone_Number) {
        let phone1 = item.Phone_Number.substring(0,3)
        let phone2 = item.Phone_Number.substring(3,6)
        let phone3 = item.Phone_Number.substring(6)
        item.Phone_Number = `(${phone1})${phone2}-${phone3}`
      }
      if (item.Company_Name) {
        let split = item.Company_Name.split(" ")
        let uppercase = split.map(item => this.capitalize(item))
        item.crmName = uppercase.join(" ")
      }
      else {
        item.crmName = `${item.Last_Name}, ${item.First_Name}`
      }
      let prospect = await this.$store.dispatch("customer/createProspect", item)
      window.open(`/customers/${prospect.id}`, "_blank")
    },
    capitalize(val) {
      let lower = val.toLowerCase()
      return `${lower.slice(0,1).toUpperCase()}${lower.slice(1)}`
    },
    canView(dealer) {
      if (this.isAdmin) return true
      else if (dealer) {
        if (dealer.pfs_dealerid === this.user.dealerId) return true
        else return false
      }
      else return false
    },
    async setProspect(val, item) {
      let related = val.Related

      // add grower id to stack
      if (!related.includes(val.Grower_Id)) {
        related.unshift(val.Grower_Id)
      }

      this.checkCustomers = true
      this.initialCustomerSearch = true
      this.growerId = val
      this.customers = [].concat(...await Promise.all(related.map(item => {
        return this.$store.dispatch("customer/checkCustomer", item)
      })))
      this.checkCustomers = false
      // console.log(grower)
        // this.growerId = val
        // item.isSelected = true
        // console.log(item)
    },
    setProspectNames(prospects) {
      return prospects.map(item => {
        item.Name = item.Company_Name ? item.Company_Name : `${item.First_Name} ${item.Last_Name}` 
        return item
      })
    },
    async prospectCoords(val) {
      this.initialSearch = true
      this.checkProspects = true
      this.customers = []
      this.initialCustomerSearch = false
      let prospects = await this.$store.dispatch("plan/getGrowerData", {
          lat: val.lat,
          lon: val.lng,
          radius: 800
      })
      this.checkProspects = false
      this.prospects = this.setProspectNames(prospects)
      this.growerIds = this.prospects
    },
  }
}
</script>

<style lang="scss" scoped>
    .prospect {
        ::v-deep  tr {
            cursor: pointer;
        }
        // ::v-deep tr:nth-child(var(--index)){
        //     display: none;
        // }
    }
</style>